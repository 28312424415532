import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { AuthGuard, subscriptionGuard } from '@garmin-avcloud/avcloud-web-utils';
import { flightOrchestratorFlagsGuard } from './core/guards/flight-orchestrator-flags/flight-orchestrator-flags.guard';
import { sessionGuard } from './core/guards/session-guard/session-guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./features/landing/landing.module').then((m) => m.LandingModule)
  },
  {
    canActivate: [sessionGuard, AuthGuard],
    path: 'debug',
    loadChildren: () => import('./features/debug/debug.module').then((m) => m.DebugModule)
  },
  {
    canActivate: [sessionGuard, AuthGuard, subscriptionGuard, flightOrchestratorFlagsGuard],
    path: 'aircraft',
    loadChildren: () => import('./features/aircraft/aircraft-routes'),
    data: {
      featureType: 'aircraft',
      redirectTo: ''
    }
  },
  {
    canActivate: [sessionGuard, AuthGuard, subscriptionGuard, flightOrchestratorFlagsGuard],
    path: 'flights',
    loadChildren: () => import('./features/flights/flights.routes').then((m) => m.routes),
    data: {
      featureType: 'flights',
      redirectTo: ''
    }
  },
  {
    canActivate: [sessionGuard, AuthGuard, subscriptionGuard],
    path: 'settings',
    loadChildren: () => import('./features/settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('./features/error/error.module').then((m) => m.ErrorModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

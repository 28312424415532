<!-- linthtml-configure img-req-alt="false"-->
<!-- linthtml-disable indent-style -->
<!-- linthtml-disable indent-width -->
<div
  class="sidebar-container"
  [class.mobile-view]="mobileView"
  [class.expanded]="expandedState">
  <div class="top-container">
    <div
      *ngIf="!mobileView"
      class="logo">
      <a
        title="Garmin Pilot Web"
        routerLink="/">
        <img
          alt="Garmin Pilot Logo"
          [src]="expandedState ? 'assets/garminPilotLogo.svg' : 'assets/garminPilotDeltaLogo.svg'">
      </a>
    </div>
    <div class="links-container">
      <pilot-nav-link
        text="Maps"
        path=""
        [icon]="SharedIcons.Maps"
        [iconOnly]="!expandedState"
        [isActiveOptions]="{ exact: true }"></pilot-nav-link>
      <pilot-nav-link
        pilotNavLinkLock
        featureType="flights"
        lockedModalTitle="Flights Page Unavailable"
        text="Flights"
        path="flights"
        [icon]="SharedIcons.Flights"
        [iconOnly]="!expandedState"></pilot-nav-link>
      <pilot-nav-link
        pilotNavLinkLock
        featureType="aircraft"
        lockedModalTitle="Aircraft Page Unavailable"
        text="Aircraft"
        path="aircraft"
        [icon]="SharedIcons.Aircraft"
        [iconOnly]="!expandedState"></pilot-nav-link>
      @if (userHasActiveSubscription()) {
        <pilot-nav-link
          text="Settings"
          path="settings"
          [icon]="SharedIcons.Settings"
          [iconOnly]="!expandedState"></pilot-nav-link>
      }
    </div>
  </div>
  <div class="bottom-container">
    <div *ngIf="!expandedState">
      <div *ngIf="!userIsAuthenticated()">
        <button flyButton
          title="Sign In"
          [options]="{
            style: 'ghost',
            size: 'medium-icononly'
          }"
          (click)="signIn()">
          <fly-icon [icon]="Icons.SignIn"></fly-icon>
        </button>
      </div>
      <div *ngIf="userIsAuthenticated()">
        <button flyButton
          title="Sign Out"
          [options]="{
            style: 'ghost',
            size: 'medium-icononly'
          }"
          (click)="signOut()">
          <fly-icon [icon]="Icons.SignOut"></fly-icon>
        </button>
      </div>
    </div>
    <div
      *ngIf="expandedState"
      class="expanded-sign-in-button">
      <button flyButton
        *ngIf="!userIsAuthenticated()"
        [options]="{ style: 'ghost' }"
        title="Sign In"
        (click)="signIn()">
        <fly-icon [icon]="Icons.SignIn"
        class="icon-left"></fly-icon>
        <span class="button-text">
          Sign In
        </span>
      </button>
      <button flyButton
        *ngIf="userIsAuthenticated()"
        [options]="{ style: 'ghost' }"
        title="Sign Out"
        (click)="signOut()">
        <fly-icon [icon]="Icons.SignOut"
        class="icon-left"></fly-icon>
        <span class="button-text">
          Sign Out
        </span>
      </button>
    </div>
    <div *ngIf="expandedState">
      <pilot-footer></pilot-footer>
    </div>
    <div class="expand-button">
      <button flyButton
        [title]="expandedState ? 'Collapse' : 'Expand'"
        [options]="{
          style: 'ghost',
          size: 'medium-icononly'
        }"
        (click)="onClickExpandButton()">
        <fly-icon [icon]="expandButtonIcon"></fly-icon>
      </button>
    </div>
  </div>
</div>
